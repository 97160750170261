/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Button, Text, TitleMain, Title, PriceWrap } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Menu"}>
        <SiteHeader />

        <Column className="--left pb--20 pt--50" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--2 pl--20 pr--20 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--left ff--3 fs--18 w--300 swpf--uppercase ls--04 lh--2" href={"/de/"} content={"Zurück"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right fs--18 w--300 swpf--uppercase ls--04 lh--2 pb--08 pt--08" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-supplementary)\">Café Romanca</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--10" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--20 pr--20 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <TitleMain className="title-box fs--154 w--300 ls--01 lh--12" style={{"maxWidth":""}} content={"Menü"}>
              </TitleMain>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--20 pt--20" name={"uvod"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1 mb--0 mt--0 pb--0 pl--20 pr--20 pt--0 flex--top" columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--style8 title-box--right fs--62" content={"Kaffee & Getränke"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim4 --anim-s3 pb--40 pt--40" anim={"4"} name={"i9pwx0kbpll"} animS={"3"}>
          
          <ColumnWrap className="column__flex el--2 pl--20 pr--20" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":500}}>
              
              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Espresso"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Espresso Doppio"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Espresso Lungo"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Café Latté"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Frappé"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Kaffee mit Eis"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":500}}>
              
              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Espresso"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Espresso Doppio"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Café Latté"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Kaffee mit Eis"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Ristretto"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Frappé"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--20 pt--20" name={"uvod"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1 mb--0 mt--0 pb--0 pl--20 pr--20 pt--0 flex--top" columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--style8 title-box--right fs--62" content={"Dessert"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim4 --anim-s3 pb--40 pt--40" anim={"4"} name={"i9pwx0kbpll"} animS={"3"}>
          
          <ColumnWrap className="column__flex el--2 pl--20 pr--20" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":500}}>
              
              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Sachertorte"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Brownies"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Karottenkuchen"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":500}}>
              
              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Eisbecher"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Milschake"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Brownies"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"17€"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}} border={null}>
          
          <ColumnWrap className="column__flex --right el--2 pb--30 pl--20 pr--20 pt--30 flex--top" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left fs--62 swpf--uppercase" content={"<span style=\"color: var(--color-dominant);\">Café Romanca</span>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--16 w--300 ls--04 lh--2 mt--30" style={{"maxWidth":570}} content={"<span style=\"color: var(--color-dominant);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"<a href=\"https://saywebpage.com\">Webproduktion stránek</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 ls--04 mt--0 pl--0" innerClassName="pb--02 pl--0" content={"<span style=\"color: var(--color-dominant);\">Nach oben</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 ls--04 mt--20 pl--0" innerClassName="pb--02 pl--0" href={"/de/fotogallerie"} content={"<span style=\"color: var(--color-dominant);\">Fotogalerie</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 ls--04 mt--20 pl--0" innerClassName="pb--02 pl--0" href={"/de/menu"} content={"<span style=\"color: var(--color-dominant);\">Menü</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 ls--04 mt--20 pl--0" innerClassName="pb--02 pl--0" href={"/de/buchung"} content={"<span style=\"color: var(--color-dominant);\">Buchung"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}